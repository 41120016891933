.page.upgrade {
    .upgrade-content {
        text-align: center;
        font-size: 1.1em;
        .exp {
            color: red;
        }
        button {
            margin: auto;
            font-size: 1.2em;
        }
    }
}