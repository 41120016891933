.slider-arrow {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 7;
}

.arrow-btn-next {
  top: 112%;
  float: right;
  margin-right: 32%;
  z-index: 7;
  width: 60px !important;
  height: 60px !important;
}
.arrow-btn-prev {
  top: 112%;
  float: left;
  margin-left: 32%;
  z-index: 7;
  width: 60px !important;
  height: 60px !important;
}

.slick-track {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  max-height: 250px !important;
}
.slick-list {
  max-height: 250px !important;
  margin: 0 -10px;
}

.slick-slide {
  margin: auto;
  height: auto !important; // ← that must not be ignored
}

.slick-slide > div {
  display: grid;
  place-items: center;
  width: 350px;
  margin: auto;
  min-height: 250px;
  padding: 0px;
}

@media (min-width: 400px) {
  .slick-track {
    max-height: 327px !important;
  }
  .slick-list {
    max-height: 327px !important;
  }

  .slick-slide > div {
    width: 450px;
    min-height: 327px;
  }
}

@media (min-width: 900px) {
  .arrow-btn-next {
    top: 30%;
    margin-right: 32%;
  }
  .arrow-btn-prev {
    top: 30%;
    margin-left: 32%;
  }

  .slick-slide > div {
    width: 95%;
  }
}

@media (min-width: 1200px) {
  .slick-track {
    max-height: 327px !important;
  }
  .slick-list {
    max-height: 327px !important;
  }

  .slick-slide > div {
    width: 450px;
    min-height: 327px;
  }
}

@media (min-width: 1370px) {
  .slick-track {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    max-height: 160px !important;
  }
  .slick-list {
    max-height: 160px !important;
    margin: 0 -10px;
  }

  .slick-slide {
    margin: auto;
    height: auto !important; // ← that must not be ignored
  }

  .slick-slide > div {
    display: grid;
    place-items: center;
    width: 93%;
    margin: auto;
    min-height: 160px;
    padding: 0px;
  }
}

#root {
  overflow: hidden;
}

.Mui-error {
  color: white !important;
}

.MuiFormLabel-colorSuccess {
  color: green !important;
}
