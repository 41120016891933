@font-face {
  font-family: "URW DIN";
  font-style: normal;
  font-weight: 400;
  src: url("/Assets/fonts/URWDIN-Regular.woff") format("woff"),
       url("/Assets/fonts/URWDIN-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "URW DIN";
  font-style: normal;
  font-weight: 500;
  src: url("/Assets/fonts/URWDIN-Medium.woff") format("woff"),
       url("/Assets/fonts/URWDIN-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "URW DIN";
  font-style: normal;
  font-weight: 700;
  src: url("/Assets/fonts/URWDIN-Bold.woff") format("woff"),
       url("/Assets/fonts/URWDIN-Bold.woff2") format("woff2");
}
