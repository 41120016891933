.page-footer {
  margin-top: auto;
  font-size: 0.9em;
  line-height: 1.3;
  margin-bottom: 30px;

  // STYLING FOR NO BANNER IMAGE

  background: rgb(243, 243, 243);
  padding: 50px 0;
  margin-bottom: 0;

  nav {
    padding: 0 5%;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    .f-navigation {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      list-style: none;
      padding: 0;
      a,
      i {
        color: $brandblue;
        transition: 0.2s;
      }
      a {
        padding: 5px;
        display: inline-block;
      }
      .fab {
        font-size: 1.875em;
      }
      &.social {
        flex-wrap: nowrap;
        a:hover i {
          color: darken($brandblue, 15%);
        }
      }
      &.apps {
        @media (max-width: 700px) {
          text-align: center;
        }
        li {
          margin: auto;
          img {
            max-height: 64px;
          }
        }
      }
      &:not(.apps) {
        @media (max-width: 700px) {
          display: none;
        }
      }
    }
  }

  .bottom-text {
    border-top: 1px solid #bbbdc0;
    padding-top: 30px;
    margin-left: 57px;
    margin-right: 57px;
    @media (max-width: $hidePlayer) {
      margin-bottom: 80px;
    }
    @media (max-width: 450px) {
      margin-left: 20px;
      margin-right: 20px;
    }
    .foundation {
      color: $brandblue;
    }

    h3 {
      color: #bbbdc0;
      padding-bottom: 5px;
    }

    .nonprofit,
    .thanks {
      color: #6e6e6e;
      @media screen and (min-width: $hidePlayer) {
        max-width: calc(100% - 140px);
      }
    }

    a {
      color: $brandblue;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    .upscope-footer {
      text-align: center;
    }

  }

  .support-banner-desktop {
    display: none;
  }

  .support-banner-mobile {
    box-sizing: border-box;
    display: none;
  }
}
