$header-height: 83px;

header {
  z-index: 900;
  .expiring {
    position: absolute;
    top: 90px;
    right: 10px;
    button {
      background: $salmon;
      color: white;
      border: 1px solid #a3181a;
      font-size: 0.75em;
      font-weight: 700;
      width: 100%;
      box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.36);
      &:hover,
      &:focus {
        background: #a3181a;
      }
    }
  }
  .mobile {
    display: none;
    z-index: 900;
    .expiring {
      @media (max-width: $hidePlayer) and (min-width: 610px) {
        top: 14px;
        right: 250px;
      }
      @media (max-width: 610px) {
        top: 70px;
      }
    }
  }
}
.header-nav {
  z-index: 900;
  list-style: none;
  .navigation,
  .action-buttons {
    transition: 0.5s;
    list-style: none;
  }
}

@media (min-width: 940px) {
  .header-nav {
    position: fixed;
    display: flex;
    justify-content: space-between;
    width: 100%;
    .close {
      display: none;
      padding: 10px;
      background: transparent;
      color: white;
      border: none;
      font-size: 1.5em;
      margin-left: auto;
    }
    .navigation,
    .action-buttons {
      transition: 0.5s;
      display: flex;
      list-style: none;
      margin-bottom: 0;
      padding-left: 30px;
      @media (max-width: 1025px) {
        padding-left: 0px;
      }
    }

    .logo {
      margin: auto 1.5em;
    }
  }
  .navigation {
    align-self: center;
    height: 100%;

    li {
      height: 100%;
      display: flex;
      align-items: center;
      border-bottom: 4px solid transparent;

      &:not(.navigation__logo-container) {
        a {
          font-size: 1.25em;
          line-height: 1;
          text-align: center;
          margin: 0 2px;
          color: $brandblue;
          padding: 15px;
          box-sizing: border-box;
          display: block;
          text-decoration: none;
          @media (max-width: 1180px) {
            font-size: 1em;
            padding: 15px 10px;
          }
        }

        &:hover {
          border-bottom: 4px solid $accent;
        }

        &:hover a {
          text-decoration: none;
        }

        a:focus {
          outline: 2px dotted $grey;
        }

        &.active {
          border-bottom: 4px solid $brandblue;
        }
      }
    }
  }

  .action-buttons {
    li {
      margin: auto 0.5em;
      button.menu-button {
        @include button-secondary($brandblue);
        margin: 10px 5px 10px 0;
        padding: 0.5em 0.8em;
      }
      .hamburger-menu {
        margin: 0;
        padding: 0;
        width: 100%;
        box-sizing: border-box;
        flex-direction: column;
        position: fixed;
        background: #2a2a2a !important;
        transform: translate(-125px, 40px);
        list-style-type: none;
        li {
          margin: 0;
          padding: 0;
          width: 100%;
          padding: 0;
          a {
            padding: 5px 15px;
            margin: 10px 0;
            display: block;
            text-decoration: none;
            height: auto;
            color: white;
          }
        }
      }
    }
    .logged-in-status {
      // position: relative;
    }
    .account,
    .login {
      @include button-secondary($accent);
      display: block;
      border-width: 3px;
      font-size: 1.1em;
      letter-spacing: 0.07em;
      font-weight: 800;
      padding: 0.5rem 1.1rem;
    }
    .logout {
      @include button-secondary($accent);
      display: none;
      position: absolute;
      width: 100%;
      top: 100%;
      color: $accent;
      border-width: 2px;
      font-size: 1.1em;
      letter-spacing: 0.07em;
      font-weight: 800;
      padding: 0.5rem 1.1rem;
      &:hover {
        display: block;
        background: rgba(135, 188, 142, 0.4);
        color: darken($accent, 10%);
      }
    }
    .donate {
      @include button-primary($accent);
      display: block;
      font-size: 1.15em;
      letter-spacing: 0.07em;
      font-weight: 800;
      padding: 0.475rem 2.5rem;
    }
    @media screen and (max-width: 1110px) {
      .account,
      .login {
        font-size: 1em;
        padding: 0.5rem 1.1rem;
      }
      .logout {
        font-size: 1em;
        padding: 0.5rem 1.1rem;
      }
      .donate {
        font-size: 1em;
        padding: 0.475rem 2.5rem;
      }
    }
  }
}

@media screen and (max-width: $hidePlayer) {
  .header-nav {
    width: 100%;
  }
}

@media screen and (max-width: 940px) {
  header {
    .mobile {
      display: flex;
      position: fixed;
      z-index: 150;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      background: white;
      .logo {
        margin: auto 5px;
        margin-right: auto;
        img {
          height: 40px;
        }
      }
      .user {
        @include button-secondary($accent);
        margin: 10px 5px 10px 0;
        padding: 0.5em 0.7em;
      }
      .donate {
        @include button-primary($accent);
        margin: 10px 5px 10px 0;
        padding: 0.5em 0.7em;
      }
      .menu-button {
        @include button-secondary($brandblue);
        margin: 10px 5px 10px 0;
        padding: 0.5em 0.8em;
      }
    }
    .header-nav {
      width: 235px;
      transition: all 1s ease;
      right: 0;
      display: none;
      position: fixed;
      background: #2a2a2a !important; //needs important to override javascript
      .navigation {
        margin: 0;
        padding: 0;
        width: 100%;
        box-sizing: border-box;
        flex-direction: column;
        li {
          margin: 0;
          padding: 0;
          width: 100%;
          padding: 0;
          a {
            padding: 5px 15px;
            margin: 10px 0;
            display: block;
            text-decoration: none;
            height: auto;
            color: white;
          }
          &.active a {
            border-left: 10px solid $brandblue;
            padding-left: 5px;
          }
        }
      }
    }
    .header-nav .action-buttons {
      display: none;
    }
    .hamburger-menu-mobile {
      margin: 0;
      padding: 0;
      width: 100%;
      box-sizing: border-box;
      flex-direction: column;
      position: fixed;
      background: #2a2a2a !important;
      transform: translate(65%, 60px);
      list-style-type: none;
      li {
        margin: 0;
        padding: 0;
        width: 100%;
        padding: 0;
        a {
          padding: 5px 10px;
          margin: 10px 0;
          display: block;
          text-decoration: none;
          height: auto;
          color: white;
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .menu-button{
    border-radius: 50%;
  }
  ul.hamburger-menu-mobile {
    transform: translate(60%, 60px);
  }
}

@media screen and (max-width: 370px) {
  header {
    .mobile {
      flex-wrap: wrap;
      justify-content: center;
      .logo {
        flex-shrink: 0;
      }
      .menu-button {
        border-radius: 50%;
      }
      .hamburger-menu-mobile {
        transform: translate(10%, 210px);
      }
    }
  }
}

.expiring-modal {
  p {
    padding: 0 25px; //do not overlap close button
  }
  p.expiring {
    color: $salmon;
    font-weight: 700;
    font-size: 1em;
    .days {
      border-bottom: solid 2px $salmon;
    }
  }
  p.details {
    font-size: 0.7em;
  }
  .primary {
    display: flex;
    justify-content: center;
    @media (max-width: 500px) {
      display: block;
    }
  }
  button {
    margin: auto;
    font-size: 0.8em;
    @media (max-width: 500px) {
      margin: 10px auto;
    }
  }
  .other-options {
    @include button-tertiary($grey);
    font-size: 0.8em;
    margin-top: 15px;
  }
}
