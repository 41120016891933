// NEW CHECKOUT COMPONENT STUFF

.recipient-note {
  @include input-standard($checkout: true);
  height: 160px;
}

@media (min-width: 600px) {
  .donate-main .date-picker-label {
    display:flex;
    .date-picker {
      margin-left: 30px;
    }
  }
}

.page.order-confirmation {
  margin-bottom: 0;
  .body {
    max-width: 800px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  h2 {
    text-align: center;
    font-weight: 600;
    max-width: 850px;
    margin: 70px auto 25px;
  }

  .initial-tips {
    width: 95%;
    max-width: 1200px;
    margin: 0 auto 50px;
    text-align: center;
    p {
      font-size: 1.1rem;
    }
  }

  .helpful-tip-box {
    @extend .white-box;
    padding: 0;
    width: 95%;
    max-width: 1200px;
    margin: 0 auto 50px;
    h3,
    p {
      margin-bottom: 0;
    }
    h3 {
      background: #e5e5e5;
      padding: 15px;
      margin-left: 70px;
      position: relative;
      &::before {
        width: 70px;
        left: -70px;
        top: 0;
        padding: 15px;
        text-align: center;
        position: absolute;
        background: $brandblue;

        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-family: "Font Awesome 5 Free";
        font-weight: 400;
        content: "\f0eb";

        color: white;
      }
      @media (max-width: 800px) {
        margin: 0;
        border-top: 5px solid $brandblue;
        font-size: 1.5rem;
        &::before {
          display: none;
        }
      }
    }
    p {
      font-size: 1.1rem;
      padding: 15px 15px 0;
    }
    p:last-of-type {
      padding-bottom: 15px;
    }
    img {
      width: 100%;
    }
    .print-page {
      margin: 0 15px 15px;
    }
  }
}

.page.mobile-help {

  @font-face {
    font-family: "URWDIN-Regular";
    src: local("URWDIN-Regular"),
          url("/Assets/fonts/URWDIN-Regular.ttf");
  }

  margin-bottom: 0;
  background-color: #2d2d2d;
  h1 {
    color: white;
    font-family: "URWDIN-Regular", "Trebuchet MS", Arial, sans-serif;
    font-weight: 600;
  }
  .body {
    max-width: 800px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  p {
    a:link {
      color: #8490ff;
    }
    a:visited {
      color: #8490ff;
    }
  }
}
  .initial-tips {
    width: 95%;
    max-width: 1200px;
    margin: 0 auto 50px;
    text-align: center;
    p {
      font-size: 1.1rem;
    }
  }

  .helpful-tip-box {
    @extend .white-box;
    padding: 0;
    width: 95%;
    max-width: 1200px;
    margin: 0 auto 50px;
    h3,
    p {
      margin-bottom: 0;
    }
    h3 {
      background: #e5e5e5;
      padding: 15px;
      margin-left: 70px;
      position: relative;
      &::before {
        width: 70px;
        left: -70px;
        top: 0;
        padding: 15px;
        text-align: center;
        position: absolute;
        background: $brandblue;

        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-family: "Font Awesome 5 Free";
        font-weight: 400;
        content: "\f0eb";

        color: white;
      }
      @media (max-width: 800px) {
        margin: 0;
        border-top: 5px solid $brandblue;
        font-size: 1.5rem;
        &::before {
          display: none;
        }
      }
    }
    p {
      font-size: 1.1rem;
      padding: 15px 15px 0;
    }
    p:last-of-type {
      padding-bottom: 15px;
    }
    img {
      width: 100%;
    }
    .print-page {
      margin: 0 15px 15px;
    }
  }

  .helpful-tip-box-mobile {
    padding: 5px;
    width: 95%;
    max-width: 1200px;
    margin: 0 auto 50px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.05);
    border-top: 5px solid #8490ff;
    h3,
    p, ul {
      margin-bottom: 0;
      color: white;
      border-radius: 10px;
      font-size: 1rem;
      padding: 0 15px 0;
    }
    h3 {
      padding: 15px;
      position: relative;
      font-weight: bold;
      color: white;
      font-size: 1.2rem;
      font-family: "URWDIN-Regular", "Trebuchet MS", Arial, sans-serif;
    }
    p:last-of-type {
      padding-bottom: 15px;
    }
    ul {
      list-style: none;
    }
    img {
      width: 90%;
      display: block;
      margin: 15px auto;
    }
    .print-page {
      margin: 0 15px 15px;
    }
  }

  .mobile-help-footer {
    color: white;
    padding: 15px 15px;
  }

.claim {
  margin: auto;
}
