.error-page {
  .subtitle {
    margin: 0 auto 40px;
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    color: #666666;
  }
  .button-container {
    display: flex; 
    justify-content: center;
  }
  .button-primary {
    @include button-primary($brandblue); 
    margin: 20px auto; 
    text-align: center;
    font-size: 18px;
    margin-right: 10px;
    
  }
  .button-secondary {
    @include button-secondary($brandblue); 
    margin: 20px auto; 
    text-align: center;
    font-size: 18px;
    margin-left: 10px;
  }

}