.post {
  font-size: 16px;
  .post-date {
    font-size: 1em;
    margin: 15px 0;
    text-align: center;
  }

  img {
    max-height: 250px;
    max-width: 90%;
    margin: 0 auto 20px;
    display: block;
  }

  div,
  p {
    @include body-copy();
    font-size: 20px;
    max-width: 680px;
    padding: 20px 0;
    margin: 0 auto;
    @media (max-width: 800px) {
      font-size: 1em;
      width: 95%;
    }
    .tiny {
      font-size: 14px;
    }
  }
  div {
    padding: 0;
  }
}

.contained-content {
  margin: 0 auto 50px auto;
  width: 90%;
  max-width: 680px;
}

.page {
  margin-bottom: 50px;
}

.contact,
.listen,
.privacypolicy {
  strong {
    font-weight: 600;
  }
  .links {
    display: flex;
    flex-wrap: wrap;
    a,
    button {
      @include button-secondary($brandblue);
      margin: 5px;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
  }
  h2 {
    @include heading2;
  }

  h3 {
    @include heading3;
  }

  .indent {
    padding-left: 30px;
  }
}

.title {
  @include heading1(center, $black);
  margin: 40px 0;
}

.hero-image {
  height: auto;
  margin-bottom: 30px;
  position: relative;
  max-width: 100%;
  margin-top: 5%;
  @media screen and (min-width: 940px) {
    left: 50%;
    right: 50%;
    margin-left: calc(-50vw + 130px);
    margin-right: calc(-50vw + 130px);
    max-width: 100vw;
    width: calc(100vw - 260px);
    margin-top: 0;
  }
}
