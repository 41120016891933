.swal2-modal {
  border-top: 5px solid $brandblue;
  width: 95%;
  max-width: 570px;
}

.swal2-overlay {
  background: rgba(0, 0, 0, 0.8);
}

.swal2-title {
  color: #595959;
  background: rgb(245, 245, 245);
  font-size: 24px;
  font-weight: 200;
  text-align: left;
  padding-right: 35px; //do not overlap close button
}

.swal2-title:not(:last-child) {
  padding-bottom: 10px;
}

.swal2-content {
  color: #595959;
  font-size: 20px;
  font-weight: 400;
  ul {
    text-align: left;
    list-style: circle;
    margin-top: 20px;
    li {
      margin-left: 20px;
    }
  }
}

.swal2-text {
  margin-top: 0;
  font-size: 20px;
  &:not(:first-child) {
    margin-top: 10px;
  }
  span {
    text-decoration: underline;
  }
}

.swal2-actions {
  text-align: center;

  .swal2-confirm,
  .swal2-deny,
  .swal2-cancel {
    &:not(.swal2-styled) {
      @include button-primary($brandblue);
      font-size: 16px;

      // Same margin the styled buttons use.
      margin: 0.3125em;
    }
  }

  .swal2-button--learn {
    @include button-secondary($brandblue);
    font-size: 16px;
    &:hover,
    &:focus {
      background: transparent;
    }
  }
}

