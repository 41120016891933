$corner-piece: 20px;

// TOGGLE
.toggle-container {
  margin: 0 auto 40px;
  width: 230px;
  background: #f8f8f8;
  border: 1px solid $brandblue;
  border-radius: 2em;
  position: relative;
  overflow: hidden;
  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  label {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: 13px 17px;
    cursor: pointer;
    span {
      z-index: 2;
      color: white;
      transition: 0.2s ease color;
    }
    .monthly {
      color: white;
    }
    .annually {
      color: inherit;
    }
  }
  .toggle {
    z-index: 1;
    background: $brandblue;
    width: 65%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -30px;
    border-radius: 2em;
    margin-left: 0;
    transition: 0.5s ease width, 0.7s ease margin;
  }
  input[type="checkbox"]:checked {
    & + .toggle {
      width: 85%;
      margin-left: 60%;
    }
    & ~ label > .monthly {
      color: inherit;
    }
    & ~ label .annually {
      color: white;
    }
  }
  input[type="checkbox"]:focus {
    & + .toggle {
      background: darken($brandblue, 10%);
    }
  }
}

.premium-th,
.plus-th {
  color: white;
  font-size: 26px;
  font-weight: 400;
  text-transform: uppercase;
}

.premium-th {
  background: $accent;
  border: 1px solid $accent;
}

.plus-th {
  background: $brandblue;
  span {
    display: block;
    font-size: 14px;
    text-transform: initial;
    font-style: italic;
  }
  span.most-popular {
    position: absolute;
    color: $brandblue;
    top: -23px;
    left: 50%;
    transform: translate(-50%);
  }
  span.new {
    margin-top: 10px;
    &.filler {
      margin: 30px;
    }
  }
}

.button-header {
  color: white;
  background: transparent;
  font-size: 50px;
  border-radius: 10px;
  font-weight: 600;
  display: block;
  margin: 0 auto;
  line-height: 1.1;
  transition: 0.2s ease-in-out all;
  span {
    font-size: 14px;
    font-weight: 400;
    display: block;
    font-style: italic;
  }
  &.premium {
    padding: 8px 17px 13px;
    border: 1px solid rgba(255, 255, 255, 0.6);
  }
  &.plus {
    padding: 8px 10px 13px;
    border: 2px solid white;
  }
  .dollar-sign {
    vertical-align: super;
    display: inline;
    font-size: 21px;
    font-style: initial;
  }
  &:hover {
    background: rgba(255, 255, 255, 0.15);
    transform: scale(1.05);
  }
}

// footer buttons
button.secondary {
  @include button-primary($accent);
}
button.standard,
button.secondary {
  display: block;
  border-radius: 4em;
}

@media screen and (min-width: 700px) {
  .plan-cards {
    display: none;
  }

  // COMPARISON CHART
  .plan-comparison {
    width: 95%;
    max-width: 1100px;
    margin: 0 auto 60px;
    border-collapse: collapse;

    thead tr:nth-of-type(1) th:nth-of-type(1) {
      padding: 27px;
    }

    thead tr:nth-of-type(2) th:nth-of-type(1) {
      padding: 38px;
    }

    .free-th {
      background: #f8f8f8;
      border: 1px solid #f8f8f8;
      font-size: 26px;
      font-weight: 400;
      text-transform: uppercase;
      color: $grey;
      padding: 6px 15px 8px;
      border-top-color: #e4e4e4;
      border-left-color: #e4e4e4;
      position: relative;
      &::before,
      &::after {
        content: "";
        position: absolute;
        background-image: url("https://assets.jazzgroove.org/rounded-corner-border.svg");
        width: $corner-piece;
        height: $corner-piece;
        z-index: 1;
        top: -1px;
        left: -1px;
      }
    }
    .premium-th,
    .plus-th {
      padding: 10px 15px 15px;
      vertical-align: bottom;
      position: relative;
    }
    .premium-th {
      &::before {
        content: "";
        position: absolute;
        background-image: url("https://assets.jazzgroove.org/rounded-corner.svg");
        width: $corner-piece;
        height: $corner-piece;
        z-index: 1;
        top: -1px;
        left: -1px;
      }
    }
    .plus-th {
      padding-top: 0px;
      .banner {
        position: relative;
        top: -8px;
        @media (min-width: 700px) and (max-width: 799px) {
          width: 160%;
          left: -30%;
        }
        @media (min-width: 800px) and (max-width: 849px) {
          width: 158%;
          left: -29%;
        }
        @media (min-width: 850px) and (max-width: 940px) {
          width: 154%;
          left: -27%;
        }
        @media (min-width: 941px) and (max-width: 1099px) {
          width: 162%;
          left: -31%;
        }
        @media (min-width: 1100px) and (max-width: 1329px) {
          width: 152%;
          left: -26%;
        }
        @media (min-width: 1330px) {
          width: 148%;
          left: -24%;
        }
      }
    }

    td,
    th {
      text-align: center;
    }
    td {
      border: 1px solid #eee;
      font-size: 1.1em;
      vertical-align: middle;
      padding: 0.5rem;
      position: relative;
    }
    tfoot td {
      border-width: 0;
      padding: 15px 0.5rem 25px;
    }
    td:first-of-type {
      text-align: left;
    }
    tr td:nth-of-type(3) {
      //set width to prevent jumping when switching monthly/annually
      width: 26%;
    }
    tbody {
      // borders
      tr:nth-child(even) {
        background: #f8f8f8;
      }
      tr:first-of-type td {
        border-top-color: #e4e4e4;
      }
      tr:last-of-type td {
        border-bottom-color: #e4e4e4;
      }
      tr td:first-of-type {
        border-left-color: #e4e4e4;
      }
      tr td {
        border-right-color: #e4e4e4;
      }

      // corners
      tr:first-of-type td:first-of-type {
        &::before,
        &::after {
          content: "";
          position: absolute;
          background-image: url("https://assets.jazzgroove.org/rounded-corner-border.svg");
          width: $corner-piece;
          height: $corner-piece;
          z-index: 1;
          top: -1px;
          left: -1px;
        }
      }
      tr:last-of-type td:first-of-type {
        &::before,
        &::after {
          content: "";
          position: absolute;
          background-image: url("https://assets.jazzgroove.org/rounded-corner-border.svg");
          width: $corner-piece;
          height: $corner-piece;
          z-index: 1;
          bottom: -1px;
          left: -1px;
          transform: rotate(-90deg);
        }
      }
    }

    tr td:last-of-type,
    .plus-th {
      border-left: 6px solid $brandblue;
      border-right: 6px solid $brandblue;
    }

    tfoot tr td:last-of-type {
      border: 6px solid $brandblue;
      border-top: 0;
      &::before,
      &::after {
        content: "";
        position: absolute;
        background-image: url("https://assets.jazzgroove.org/rounded-corner-blue.svg");
        width: $corner-piece;
        height: $corner-piece;
        z-index: 1;
        bottom: -6px;
      }
      &::before {
        left: -6px;
        transform: rotate(-90deg);
      }
      &::after {
        right: -6px;
        transform: rotate(180deg);
      }
    }

    // footer buttons
    button.standard,
    button.secondary {
      width: 100%;
      margin: 10px auto 0;
    }

    // icons
    .fas {
      padding-top: 0.5rem;
      padding-bottom: 0.3rem;
      font-size: 1.5em;
    }
    .fa-times-circle {
      color: $grey;
    }
    .fa-check-circle {
      color: $accent;
    }
  }
}

@media (max-width: 699px) {
  .plan-comparison {
    display: none;
  }

  .plan-cards {
    overflow: hidden;
  }

  .card-container {
    width: 85%;
    margin: 0 auto 40px;
    box-shadow: 3px 5px 8px rgba(0, 0, 0, 0.05),
      -1px 5px 8px rgba(0, 0, 0, 0.05);
    max-width: 400px;
    border-radius: 20px; //also set on header and footer
  }

  .plus-th,
  .premium-th,
  .feature-list,
  .footer {
    padding: 15px;
  }

  .plus-th,
  .premium-th {
    text-align: center;
  }

  .premium-th {
    border-radius: 20px 20px 0 0;
  }
  .banner {
    width: 126%;
    left: -13%;
    top: 1px;
    position: relative;
  }

  .plan-cards .feature-list {
    background: #f8f8f8;
    p {
      margin: 0;
    }
  }

  .footer {
    border-radius: 0 0 20px 20px;
    button {
      margin: 0 auto;
    }
  }
}

.info-container {
  position: relative;
  display: inline-block;
  background: transparent;
  border: none;
  &.underline {
    text-decoration: underline;
  }

  .fas {
    color: $grey;
    font-size: 14px;
  }

  .tooltiptext {
    visibility: hidden;
    width: 250px;
    background-color: white;
    color: $black;
    text-align: center;
    border-radius: 6px;
    border: 1px solid $brandblue;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 14px;
    box-shadow: 3px 2px 8px rgba(0, 0, 0, 0.05),
      -1px 2px 8px rgba(0, 0, 0, 0.05);
    cursor: default;
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%);
      border-width: 7px;
      border-style: solid;
      border-color: $brandblue transparent transparent transparent;
    }
  }

  &:hover,
  &:focus {
    .fas {
      color: $brandblue;
    }
  }

  &:hover .tooltiptext,
  &:focus .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  &:focus {
    outline: 0;
  }
}
