// COLORS

// old colors, keeping for now incase we need to revert
// $brandblue: #65a6e8;
// $accent: #7BAB7D;

$brandblue: #276db2;
$lightblue: #adcff2;
$accent: #008752;
$salmon: #e36a62;
$salmon: #c73a3c;
$grey-background: #f5f5f5;
$light-grey: #cccccc;
$grey: #737373;
$black: #1e1e1e;

// Page variables
$hidePlayer: 900px;
$column: 1050px;
$donations-border: 1px solid $light-grey;

$fontFamily: "URW DIN", Helvetica, sans-serif;
$fontFamilySecondary: futura-pt-bold, "Trebuchet MS", sans-serif;

// PAGE CONTAINER

.flex-container {
  display: flex;
  position: relative;
  min-height: 100vh;
}

/*
TYPOGRAPHY
*/

@mixin body-copy($color: $black) {
  font-size: 1.25em;
  font-weight: 400;
  color: $color;
  line-height: 1.5;
  margin-bottom: 1.25em;
  @media (max-width: 800px) {
    font-size: 1em;
  }
}

@mixin heading1($align: center, $color: #{$black}) {
  text-align: $align;
  font-weight: 600;
  color: $color;
}

@mixin heading2($color: #{$black}) {
  font-size: 1.875em;
  margin: 30px 0 20px 0;
  font-weight: 200;
  color: $color;
  border-bottom: 2px solid $light-grey;
  padding-bottom: 5px;
}

@mixin heading3($color: #{$black}) {
  font-size: 1.25em;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 5px;
  color: $color;
}

@mixin heading4($color: #{$black}) {
  font-size: 1.1;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 5px;
  color: $color;
}

// ELEMENTS

@mixin button-primary($background: $brandblue, $color: white) {
  font-family: $fontFamily;
  border-radius: 2em;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.04em;
  font-size: 0.95em;
  transition: all 0.2s ease;
  border-style: solid;
  border-width: 2px;
  padding: 0.5em 1em;
  box-sizing: border-box;
  text-decoration: none;

  color: $color;
  background: $background;
  border-color: $background;

  &:hover,
  &:focus {
    box-shadow: 1px 1px 4px rgba(34, 34, 34, 0.1),
      -1px 1px 4px rgba(34, 34, 34, 0.1);
    text-decoration: none;
    background: darken($background, 15%);
    border-color: darken($background, 15%);
    color: $color;
    outline: 0;
  }
  &:focus {
    box-shadow: 1px 1px 4px $brandblue, -1px 1px 4px $brandblue;
  }
}

@mixin button-secondary($color: $accent, $background: transparent) {
  font-family: $fontFamily;
  border-radius: 2em;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.04em;
  font-size: 0.95em;
  transition: all 0.2s ease;
  border-style: solid;
  border-width: 2px;
  padding: 0.5em 1em;
  box-sizing: border-box;
  text-decoration: none;

  color: $color;
  border-color: currentColor;
  background: $background;

  &:hover,
  &:focus {
    box-shadow: 1px 1px 4px rgba(34, 34, 34, 0.1),
      -1px 1px 4px rgba(34, 34, 34, 0.1);
    text-decoration: none;
    color: darken($color, 15%);
    outline: none;
  }
  &:focus {
    box-shadow: 1px 1px 4px $brandblue, -1px 1px 4px $brandblue;
  }
}

@mixin button-tertiary($color: $grey) {
  color: $color;
  font-weight: 800;
  border: none;
  background: transparent;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  padding: 0;
  border: 2px solid transparent;
  transition: 0.1s ease-in all;
  position: relative;
  font-size: 0.95em;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: currentColor;
    width: 100%;
    opacity: 0;
  }
  &:hover,
  &:focus {
    outline: 0;
    text-decoration: none;
    outline: none;
    &::after {
      opacity: 1;
    }
  }
}

@mixin input-standard($checkout: false) {
  background: white;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  width: 100%;
  display: block;
  font-size: 18px;
  margin: 10px 0px;
  &:focus {
    outline: none;
  }
  &:disabled {
    border-color: $grey;
  }

  @if $checkout {
    border-radius: 3px;
    background: $grey-background;
    border-color: $light-grey;
    padding: 5px 10px;
    &:focus {
      outline: none;
      border-color: $brandblue;
    }
  } @else {
    border-radius: 2em;
    padding: 5px 15px;
    border-color: $brandblue;
    &:focus {
      border-color: $accent;
      background: #eeeeee;
    }
  }
}

.channel-selector {
  cursor: pointer;
  background: transparent;
  p {
    margin-bottom: 0;
  }
}

@keyframes pulseOpacity {
  0% {
    color: rgba(255, 255, 255, 0.8);
  }
  50% {
    color: rgba(255, 255, 255, 0.5);
  }
  100% {
    color: rgba(255, 255, 255, 0.8);
  }
}

.tiny {
  font-size: 14px;
}

.center-button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.white-box,
.green-box {
  padding: 20px 25px;
  margin-top: 40px;
  box-shadow: 1px 3px 5px $light-grey;
  border-radius: 5px;
  overflow: hidden;
  .divider {
    width: calc(100% + 50px);
    position: relative;
    left: -25px;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-color: rgba(0, 0, 0, 0.1);
  }
}

.white-box {
  background: white;
}

.green-box {
  background: #bad3bb;
  color: #202920;
  .body,
  .tiny {
    color: inherit;
  }
}

.zoom {
  cursor: zoom-in;
}
