.swal-select {
  width: 150px;
  margin: 0 auto 10px;
  &.select-gift {
    width: auto;
  }
}
.swal-input {
  @include button-primary($brandblue);
  padding: 6px 10px;
  font-size: 16px;
}
