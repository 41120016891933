.swal-modal.login {
  .swal-content {
    padding: 0px;
    margin-top: 0;
  }
}

.login {
  .logom {
    background: #2b2a2a;
    width: 100%;
    img {
      width: 35%;
      padding: 20px;
    }
  }
  .login-box {
    margin: 0 auto;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;

    h1.login-title {
      padding: 10px 15px 0;
      font-size: 24px;
      line-height: 1.3;
      font-weight: 600;
    }

    p.login-title {
      padding: 10px 30px 0;
    }
    ul {
      box-sizing: border-box;
      width: 100%;
      display: flex;
      justify-content: center;
      list-style: none;
      margin: 0 0 15px;
      padding: 0 10px;

      li {
        margin: 0;
        padding: 0;
        flex-grow: 1;
      }

      button {
        text-align: center;
        cursor: pointer;
        color: lighten($grey, 20%);
        font-size: 22px;
        border: 0;
        border-bottom: 4px solid lighten($grey, 20%);
        margin: 0;
        padding: 25px 10px 20px;
        width: 100%;
        transition: 0.2s ease all;
        background: white;
        &:hover,
        &:focus {
          color: $grey;
          border-bottom: 4px solid $grey;
          outline: 0;
          background: #e6e6e6;
        }
        &.selected {
          color: $brandblue;
          border-bottom: 4px solid $brandblue;
        }
      }
    }
    form {
      padding: 10px 30px 0;
      box-sizing: border-box;
      @media (max-width: 700px) {
        padding: 20px;
      }
      @media (max-width: 500px) {
        padding: 20px 10px;
      }
      br {
        display: none;
      }
    }
    .forgot {
      @include button-secondary($grey);
      font-size: 16px;
      padding: 0.3em 1.1em;
      margin-bottom: 20px;
      border-width: 1px;
    }

    .policy {
      font-size: 0.9rem;
      color: black;
      text-align: center;
      margin: 0 10px 10px;
      a {
        color: $brandblue;
      }
    }

    .title {
      @include heading1(center, $black);
      padding-bottom: 30px;
      margin-top: 50px;
    }

    input[type="checkbox"] {
      display: none;
    }

    label {
      display: inline-block;
      width: 20px;
      height: 20px;

      cursor: pointer;
      position: relative;

      margin-left: 5px;
      margin-right: 10px;
      top: 8px;

      &:before {
        content: "";
        display: inline-block;
        border-radius: 30px;
        width: 20px;
        height: 20px;
        padding: 2px;
        position: absolute;
        left: 0;
        bottom: 1px;
        border: 1px solid $grey;
      }
    }

    input[type="checkbox"]:checked + label:before {
      content: "\2713";
      font-size: 20px;
      color: $grey;
      text-align: center;
      line-height: 20px;
    }

    // login button
    button.submit {
      @include button-primary($accent);
      margin: 20px auto 10px;
      width: 60%;
      @media screen and (max-width: 500px) {
        width: auto;
        display: block;
      }
    }

    .login-divider {
      position: relative;
      font-weight: 600;
      text-transform: uppercase;
      background: white;
      padding: 10px;
      margin: 0 30px;
      z-index: 1;

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 3px;
        width: 45%;
        background: $light-grey;
        z-index: 0;
      }
      &::before {
        left: 0;
      }
      &::after {
        right: 0%;
      }
    }

    .social {
      width: 100%;
      position: relative;
      overflow: hidden;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      padding: 0 30px;

      @media screen and (max-width: 500px) {
        display: block;
      }
    }

    .social-login-button {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1 1 50%;
      border: 1px solid $light-grey;
      font-size: 18px;
      border-radius: 2em;
      background: white;
      padding: 7px 15px;
      img {
        height: auto;
        width: 20px;
        margin-right: 5px;
      }

      &:hover {
        border-color: $brandblue;
      }

      @media screen and (max-width: 500px) {
        margin: 0 auto 15px;
      }

      &:last-of-type {
        margin-left: 15px;
        @media screen and (max-width: 500px) {
          margin: 0 auto;
        }
      }
    }

    .already {
      text-align: center;
      font-size: 0.9rem;
      padding-bottom: 5px;
      a {
        color: $brandblue;
      }
    }
  }
}

.password-field {
  position: relative;
  margin-bottom: 20px;
  button {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    border: none;
    background: transparent;
    color: $light-grey;
    &:hover {
      color: $black;
    }
  }
}

.reset-password,
.new-password {
  .back {
    @include button-secondary($grey);
    padding: 0.3em 0.8em;
    margin: 20px auto 20px 30px;
    font-size: 0.7em;
  }
  h1 {
    padding: 10px 15px 0;
    font-size: 30px;
    line-height: 1.3;
    font-weight: 600;
  }
}

.new-password {
  padding: 30px 30px 0;
  input[type="submit"].standard {
    margin-top: 0;
  }
}

.form-error-message,
.btn-link {
  color: white;
  display: block;
  margin: 10px auto;
  text-align: center;
  font-size: 1em;
  position: relative;
  padding: 5px 10px;
  z-index: 1;
  &::after {
    content: "";
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: darken($salmon, 15%);
    border-radius: 5px;
    z-index: -1;
  }

  &:hover {
    color: white;
  }
}

.form-error-message {
  max-width: 500px;
  margin-left: auto; 
  margin-right: auto; 
}

.form-warning-message,
.btn-link {
  color: white;
  display: block;
  margin: 10px auto;
  text-align: center;
  font-size: 1em;
  position: relative;
  padding: 5px 10px;
  z-index: 1;
  &::after {
    content: "";
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: darken($brandblue, 15%);
    border-radius: 5px;
    z-index: -1;
  }

  &:hover {
    color: white;
  }
}

.pro-tip {
  @extend .green-box;
  padding: 10px;
  font-size: 0.8em;
  margin-top: 30px;
  font-weight: 400;
  box-shadow: none;
}
