.announcement {
  position: fixed;
  top: 0;
  width: 100%;
  flex-basis: 100%;
  background: #4d535a;
  line-height: 25px;
  text-align: center;
  padding: 5px 0;
  color: white;
  .scroll-left {
    margin: auto;
    background: #4d535a;
    padding-bottom: 2px;
  }
  .close {
    position: absolute;
    right: 10px;
    top: 5px;
    background: #4d535a;
    border: 0;
    color: white;
    font-size: 1.4em;
    line-height: 1em;
    padding: 0;
    border-radius: 0;
  }
}

.announcement__content {
  font-size: 1.1em;
}

@media (max-width: 1200px) {
  .scroll-left {
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
  }
  .scroll-left .announcement__content {
    display: inline-block;
    padding-left: 100%;
    animation: scroll-left 20s linear infinite;
  }
}
@media (max-width: 900px) {
  .scroll-left .announcement__content {
    animation: scroll-left 17s linear infinite;
  }
}
@media (max-width: 600px) {
  .scroll-left .announcement__content {
    animation: scroll-left 14s linear infinite;
  }
}

/* Move it (define the animation) */
@keyframes scroll-left {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
