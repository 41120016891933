.carouselPadding {
  margin-left: 5px;
  max-width: 285px;
  min-width: 285px;
}

@media (max-width: 900px) {
  .carouselPadding {
    margin-left: 5px;
    max-width: 185px;
    min-width: 185px;
  }
}
