.jazz-tonight {
  .jazz-tonight-banner {
    height: calc((100vw - 260px) * 0.37);
    @media screen and (max-width: 1000px) and (min-width: $hidePlayer) {
      height: calc((80vw - 60px) * 0.37);
    }
    margin-bottom: 30px;
    background: url("../temp.jpg") center center;
    background-size: cover;
    position: relative;
    max-width: 100%;
    @media (max-width: $hidePlayer) and (min-width: 600px) {
      height: calc(100vw * 0.45);
      background-position: 15% bottom;
    }
    @media (max-width: 600px) and (min-width: 350px) {
      height: calc(100vw * 0.6);
      background-position: 15% bottom;
    }
    @media screen and (max-width: 450px) {
      height: auto;
      background-position: right bottom;
    }
  }
  .jazz-tonight-banner-content {
    margin-left: 42%;
    @media screen and (max-width: 450px) {
      margin-left: 30px;
      padding-bottom: 30px;
    }
  }
  .jazz-tonight-banner-content img {
    display: block;
    width: 50%;
    max-width: 199px;
    margin: 0 0 20px 0;
    padding-top: 6%;
    @media (max-width: 1150px) {
      margin: 0;
    }
  }
  .jazz-tonight-banner-header,
  .jazz-tonight-banner-subheader {
    font-weight: 300;
  }
  .jazz-tonight-banner-header {
    font-size: 36px;
    padding-bottom: 5px;
    color: #000;
  }
  .jazz-tonight-banner-subheader {
    font-size: 28px;
    color: #fff;
    line-height: 1;
  }
  .jazz-tonight-banner-subheader2 {
    font-size: 20px;
    padding: 5px 5px 5px 0;
    color: #525f71;
  }
  @media (min-width: $hidePlayer) and (max-width: 1150px) {
    .jazz-tonight-banner-content img {
      max-width: 170px;
    }
    .jazz-tonight-banner-header {
      font-size: 28px;
    }
    .jazz-tonight-banner-subheader {
      font-size: 20px;
    }
    .jazz-tonight-banner-subheader2 {
      font-size: 16px;
    }
  }
  @media (max-width: $hidePlayer) {
    .jazz-tonight-banner-content img {
      max-width: 170px;
      padding-top: 10%;
    }
  }
  @media screen and (max-width: 700px) {
    .jazz-tonight-banner-content img {
      max-width: 150px;
    }
    .jazz-tonight-banner-header {
      font-size: 28px;
    }
    .jazz-tonight-banner-subheader {
      font-size: 22px;
    }
    .jazz-tonight-banner-subheader2 {
      font-size: 16px;
    }
  }

  .gallery {
    text-align: center;
  }
}
