.page.sonos {
  background: $grey-background;
  margin-bottom: 0;

  .body {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }

  .sonos-login {
    @extend .white-box;
    max-width: 550px;
    margin-top: 0;
    box-shadow: none;

    input[type="email"],
    input[type="password"],
    input[type="text"] {
      margin-bottom: 20px;
      padding: 10px 15px;
    }

    p {
      text-align: center;
    }

    .logo {
      display: block;
      margin: 0 auto 20px;
      max-width: 125px;
    }

    button {
      margin: 0 auto;
    }

    .submit {
      margin-bottom: 20px;
      font-size: 1.2rem;
    }

    .tertiary {
      @include button-tertiary($grey);
      display: block;
    }

    .new {
      margin: 15px auto;
    }

    .login-divider {
      position: relative;
      font-weight: 600;
      text-transform: uppercase;
      background: white;
      padding: 0;
      margin: 20px 0;
      z-index: 1;

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 3px;
        width: 50%;
        background: $light-grey;
        z-index: 0;
      }
      &::before {
        left: -40px;
      }
      &::after {
        right: -40px;
      }
    }

    .social {
      width: 100%;
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 500px) {
        display: block;
      }
    }

    .social-login-button {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1 1 50%;
      border: 1px solid $light-grey;
      font-size: 18px;
      border-radius: 2em;
      background: white;
      padding: 7px 15px;
      img {
        height: auto;
        width: 20px;
        margin-right: 5px;
      }

      &:hover {
        border-color: $brandblue;
      }

      @media screen and (max-width: 500px) {
        margin: 0 auto 15px;
      }

      &:last-of-type {
        margin-left: 15px;
        @media screen and (max-width: 500px) {
          margin: 0 auto;
        }
      }
    }
  }

  .sonos-lander {
    .hero {
      background: center / cover no-repeat
        url("../../Assets/trumpet-player-bw-unsplash.jpg");

      color: white;
      text-align: center;

      min-height: 80vh;
      padding: 50px 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .logo {
        max-width: 200px;

        margin-bottom: 30px;
      }

      h2 {
        margin-bottom: 50px;
      }

      .button-subtext {
        font-style: italic;
      }
    }

    .free-account-button {
      @include button-primary($brandblue);
      font-size: 1.25em;
      padding: 0.65em 1.5em;
      margin-bottom: 7px;
    }

    .sub-hero {
      background: #2b2a2a;
      color: white;
      padding: 20px 15px;
      text-align: center;
      h3 {
        text-transform: uppercase;
        margin-bottom: 3px;
        font-weight: 600;
        letter-spacing: 0.1em;
        font-size: 1.5rem;
      }
      p {
        margin-bottom: 0;
        letter-spacing: 0.03em;
      }
    }
  }
  .upgrade-premium-section {
    padding: 70px 15px;
    background: white;
    h2 {
      text-align: center;
      margin-bottom: 20px;
    }
    .body {
      text-align: center;
      margin-bottom: 60px;
    }
    .upgrade-cards {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      max-width: 600px;
      margin: 0 auto;
      @media (max-width: 650px) {
        display: block;
      }
    }
    .upgrade-card {
      background: linear-gradient($brandblue, #1f5f93);
      color: white;
      padding: 25px;
      border-radius: 10px;
      text-align: center;
      flex-basis: 47%;
      h3 {
        line-height: 1;
        margin-bottom: 25px;
        font-weight: 400;
      }
      @media (max-width: 650px) {
        margin-bottom: 25px;
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .price {
      font-size: 2rem;
      line-height: 1;
      margin-bottom: 0;
      .dollar-sign {
        vertical-align: super;
        font-size: 0.5em;
      }
    }
    .small,
    .price-sub {
      color: rgba(255, 255, 255, 0.8);
      margin-bottom: 0;
    }
    .sign-up {
      @include button-secondary(white);
      display: block;
      margin: 25px auto;
      width: 70%;
    }
    .see-benefits {
      background: transparent;
      outline: 0;
      border: 0;
      color: rgba(255, 255, 255, 0.8);
      border-top: 1px solid rgba(255, 255, 255, 0.5);
      width: 100%;
      padding-top: 10px;
    }
    .benefits {
      margin-top: 15px;
      padding: 0;
      li {
        line-height: 1;
        margin-bottom: 10px;
        list-style-type: none;
      }
    }
  }
  .final-pitch {
    text-align: center;
    padding: 50px 15px;
  }
  .sonos-footer {
    background: #2b2a2a;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    padding: 30px 15px;
    .small {
      margin-bottom: 5px;
    }
  }
}
