$radio-fill-color: $brandblue !default;
$radio-empty-color: $brandblue !default;
$radio-border: 2px solid $radio-fill-color !default;
$input-disabled-color: rgba(0, 0, 0, 0.42) !default;
$input-disabled-solid-color: #949494 !default;
$input-disabled-border: 1px dotted $input-disabled-color !default;

.legacy-radio-button {
  // hide default input
  &[type="radio"]:not(:checked),
  &[type="radio"]:checked {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  // text container
  &[type="radio"]:not(:checked) + span,
  &[type="radio"]:checked + span {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    font-size: 1rem;
    transition: 0.28s ease;
    user-select: none;
  }

  // ::before = outside circle
  // ::after = inside dot

  &[type="radio"] + span::before,
  &[type="radio"] + span::after {
    content: "";
    position: absolute;
    left: 0;
    top: 25%;
    margin: 4px;
    width: 16px;
    height: 16px;
    z-index: 0;
    transition: 0.28s ease;
  }

  &[type="radio"]:not(:checked) + span::before,
  &[type="radio"]:not(:checked) + span::after,
  &[type="radio"]:checked + span::before,
  &[type="radio"]:checked + span::after,
  &[type="radio"].with-gap:checked + span::before,
  &[type="radio"].with-gap:checked + span::after {
    border-radius: 50%;
  }

  &[type="radio"]:not(:checked) + span::before,
  &[type="radio"]:not(:checked) + span::after {
    border: 2px solid $salmon;
  }

  &[type="radio"]:not(:checked) + span::after {
    transform: scale(0);
  }

  &[type="radio"]:checked + span::before {
    border: 2px solid transparent;
  }

  &[type="radio"]:checked + span::after,
  &[type="radio"].with-gap:checked + span::before,
  &[type="radio"].with-gap:checked + span::after {
    border: $radio-border;
  }

  &[type="radio"]:checked + span::after,
  &[type="radio"].with-gap:checked + span::after {
    background-color: $radio-fill-color;
  }

  &[type="radio"]:checked + span::after {
    transform: scale(1.02);
  }

  &[type="radio"].with-gap:checked + span::after {
    transform: scale(0.5);
  }

  &[type="radio"].tabbed:focus + span::before {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
  }

  &[type="radio"].with-gap:disabled:checked + span::before {
    border: 2px solid $input-disabled-color;
  }

  &[type="radio"].with-gap:disabled:checked + span::after {
    border: none;
    background-color: $input-disabled-color;
  }

  &[type="radio"]:disabled:not(:checked) + span::before,
  &[type="radio"]:disabled:checked + span::before {
    background-color: transparent;
    border-color: $input-disabled-color;
  }

  &[type="radio"]:disabled + span {
    color: $input-disabled-color;
  }

  &[type="radio"]:disabled:not(:checked) + span::before {
    border-color: $input-disabled-color;
  }

  &[type="radio"]:disabled:checked + span::after {
    background-color: $input-disabled-color;
    border-color: $input-disabled-solid-color;
  }
}

.channel .legacy-radio-button {
  &[type="radio"]:checked + span::after,
  &[type="radio"].with-gap:checked + span::after {
    background-color: $brandblue;
  }

  &[type="radio"]:checked + span::after,
  &[type="radio"].with-gap:checked + span::before,
  &[type="radio"].with-gap:checked + span::after {
    border: 2px solid $brandblue;
  }

  &[type="radio"]:not(:checked) + span::before,
  &[type="radio"]:not(:checked) + span::after {
    border: 2px solid $radio-empty-color;
  }

  &[type="radio"]:focus + span::before,
  &[type="radio"]:focus + span::after {
    background: $light-grey;
  }
}

.input-radio-button-container {
  display: inline-block;
  overflow: hidden;
  padding: 0 10px 10px 0;
  font-size: 16px;
  label {
    cursor: pointer;
  }
}

$radio-button-size: 20px;
.input-radio-button-fake {
  box-sizing: border-box;
  display: inline-block;
  margin-top: 5px;
  margin-right: 5px;
  transition: all 0.3s;
  border: 2px solid $brandblue;
  border-radius: 50%;
  width: $radio-button-size;
  height: $radio-button-size;
  position: relative;
  top: 3px;
  background: white;
  cursor: pointer;
  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: $radio-button-size - 9px;
    height: $radio-button-size - 9px;
    box-sizing: border-box;
    background: $brandblue;
    border-radius: 50%;
  }
}

.input-radio-button-selected {
  .input-radio-button-fake::after {
    content: "";
  }
}

.legacy-radio-button {
  &[type="radio"]:disabled ~ .input-radio-button-fake {
    background-color: transparent;
    border-color: #b9b9b9;
    cursor: default;
  }

  &[type="radio"]:disabled ~ label {
    color: #b9b9b9;
    cursor: default;
  }

  &[type="radio"]:checked ~ .input-radio-button-fake::after {
    content: "";
  }

  &[type="radio"]:focus ~ label {
    outline: 2px dotted $grey;
  }
}
