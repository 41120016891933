@use 'sass:math';

.channels {
  background: white;
  color: black;
  box-shadow: 0px 0px 5px rgba(166, 166, 166, 0.5);
  border-radius: 10px;
  position: absolute;
  z-index: 100;
  h3 {
    font-size: 16px;
    color: $grey;
    border-bottom: 1px solid $light-grey;
    width: 100%;
    padding: 15px 10px 2px 5px;
  }
  .premium {
    color: $salmon;
    padding-top: 0;
    margin-bottom: 2px;
    padding-bottom: 5px;
  }

  form {
    p {
      font-size: 14px;
      margin-bottom: 0;
      color: $grey;
    }

    [type="radio"]:not(:checked) + span,
    [type="radio"]:checked + span {
      position: relative;
      padding-left: 30px;
      cursor: pointer;
      display: block;
      line-height: 1;
      height: auto;
      font-size: 1rem;
      transition: 0.28s ease;
      user-select: none;
    }

    [type="radio"] + span:before,
    [type="radio"] + span:after {
      top: 8px;
      margin: 3px;
    }

    .channel {
      margin: 5px 0;
      width: 100%;
      padding: 10px 7px;
      box-sizing: border-box;
      cursor: pointer;
      transition: all ease 0.1s;
      &:hover {
        background-color: #e6e6e6;
        background-size: 130%;
      }
      @media (max-height: 600px) {
        padding: 5px 10px;
      }
      &:last-of-type {
        margin-bottom: 10px;
      }
      .channel-name {
        padding-bottom: 5px;
        margin-bottom: 5px;
        font-size: 17px;
        border-bottom: 1px solid $light-grey;
        color: $black;
        span {
          font-weight: 600;
        }
      }
    }
  }
  .close-channel-selector {
    background: transparent;
    border: none;
    border-top: 1px solid $light-grey;
    width: 100%;
    font-size: 14px;
    text-align: center;
    padding: 5px;
    color: $grey;
    border-radius: 0 0 10px 10px;
    transition: 0.2s ease all;
    &:hover {
      color: $black;
      background: #e6e6e6;
    }
  }
}

.gear-modal {
  text-align: left;

  .quality-options-container,
  .external-player-option-container,
  .listening-options-container {
    margin-bottom: 40px;
    h2 {
      margin-bottom: 5px;
    }
    p {
      font-size: 18px;
    }
    a {
      color: $brandblue;
      text-decoration: underline;
    }
    .listen {
      color: $brandblue;
      border: 0;
      text-decoration: underline;
      padding: 0;
      font-size: 18px;
    }
  }
}

.get-premium-modal {
  li {
    font-size: 20px;
    padding: 5px 0;
  }
}

.song-image {
  /* default */
  height: 200px;
  width: 200px;
  &.jazzgroove-mix1,
  &.BLOCK {
    background-image: url("https://assets.jazzgroove.org/sonos/Mix1.jpg");
    background-size: cover;
  }
  &.jazzgroove-mix2 {
    background-image: url("https://assets.jazzgroove.org/sonos/Mix2.jpg");
    background-size: cover;
  }
  &.jazzgroove-dreams {
    background-image: url("https://assets.jazzgroove.org/sonos/Dreams.jpg");
    background-size: cover;
  }
  &.jazzgroove-gems {
    background-image: url("https://assets.jazzgroove.org/sonos/Gems.jpg");
    background-size: cover;
  }
  &.jazzgroove-smooth {
    background-image: url("https://assets.jazzgroove.org/sonos/Smooth.jpg");
    background-size: cover;
  }
  background-image: url("https://assets.jazzgroove.org/5.svg");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
}

$globe-width: 4px;
$globe-height: 11px;
$globe-spacing: 16px;
$globe-spread: 3px;
$light-off-opacity: 0.4;

.lightrope {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  margin: -10px 0 0 0;
  padding: 0;
  pointer-events: none;
  width: 100%;
  li {
    position: relative;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: $globe-width;
    height: $globe-height;
    border-radius: 50%;
    margin: 4px math.div($globe-spacing, 2);
    display: inline-block;
    background: rgba(0, 247, 165, 1);
    box-shadow: 0px math.div($globe-height, 6) $globe-width * 2 $globe-spread
      rgba(0, 247, 165, 1);
    animation-name: flash-1;
    animation-duration: 2s;
    &:nth-child(2n + 1) {
      background: rgba(0, 255, 255, 1);
      box-shadow: 0px math.div($globe-height, 6) $globe-width * 2 $globe-spread
        rgba(0, 255, 255, 0.5);
      animation-name: flash-2;
      animation-duration: 0.4s;
    }
    &:nth-child(4n + 2) {
      background: rgba(247, 0, 148, 1);
      box-shadow: 0px math.div($globe-height, 6) $globe-width * 2 $globe-spread
        rgba(247, 0, 148, 1);
      animation-name: flash-3;
      animation-duration: 1.1s;
    }
    &:nth-child(odd) {
      animation-duration: 1.8s;
    }
    &:nth-child(3n + 1) {
      animation-duration: 1.4s;
    }
    &:before {
      content: "";
      position: absolute;
      background: #222;
      width: ($globe-width - 2);
      height: math.div($globe-height, 3);
      border-radius: 3px;
      top: (0 - math.div($globe-height, 6));
      left: 1px;
    }
    &:after {
      content: "";
      top: (0 - math.div($globe-height, 2));
      left: $globe-width - 3;
      position: absolute;
      width: 20px;
      height: (math.div($globe-height, 3) * 2);
      border-bottom: solid #222 2px;
      border-radius: 50%;
    }
    &:last-child:after {
      content: none;
    }
    // &:first-child {
    //   margin-left: -$globe-spacing;
    // }
  }
}
@keyframes flash-1 {
  0%,
  100% {
    background: rgba(0, 247, 165, 1);
    box-shadow: 0px math.div($globe-height, 6) $globe-width * 2 $globe-spread
      rgba(0, 247, 165, 1);
  }
  50% {
    background: rgba(0, 247, 165, $light-off-opacity);
    box-shadow: 0px math.div($globe-height, 6) $globe-width * 2 $globe-spread
      rgba(0, 247, 165, 0.2);
  }
}
@keyframes flash-2 {
  0%,
  100% {
    background: rgba(0, 255, 255, 1);
    box-shadow: 0px math.div($globe-height, 6) $globe-width * 2 $globe-spread
      rgba(0, 255, 255, 1);
  }
  50% {
    background: rgba(0, 255, 255, $light-off-opacity);
    box-shadow: 0px math.div($globe-height, 6) $globe-width * 2 $globe-spread
      rgba(0, 255, 255, 0.2);
  }
}
@keyframes flash-3 {
  0%,
  100% {
    background: rgba(247, 0, 148, 1);
    box-shadow: 0px math.div($globe-height, 6) $globe-width * 2 $globe-spread
      rgba(247, 0, 148, 1);
  }
  50% {
    background: rgba(247, 0, 148, $light-off-opacity);
    box-shadow: 0px math.div($globe-height, 6) $globe-width * 2 $globe-spread
      rgba(247, 0, 148, 0.2);
  }
}
