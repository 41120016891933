/**
  Styles to ease the transition away from Bootstrap.
  Until we replace all items with MUI components, we will use this to keep
  styles consistent with how they were in Bootstrap.
 */

.h1, .h2, .h3, .h4, .h5, .h6,
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  font-family: $fontFamilySecondary;
  font-weight: 700;
}

.h1, h1 { font-size: 2.5rem; }
.h2, h2 { font-size: 2rem; }
.h3, h3 { font-size: 1.75rem; }
.h4, h4 { font-size: 1.5rem; }
.h5, h5 { font-size: 1.25rem; }
.h6, h6 { font-size: 1rem; }
