.activated {
  a { 
    color: $brandblue !important;
  }

  strong { 
    font-weight: 600;
    margin: 10px 0; 
    width: 100%;
    font-size: 24px;
  }
  p {
    padding: 10px 0;
  }
  .links {
    display: flex;
    flex-wrap: wrap;
    a,
    button {
      @include button-secondary($brandblue); 
      margin: 5px;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
  }
  h2 {
    @include heading2;
  }

  h3 {
    @include heading3;
  }
}